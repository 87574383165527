import React from "react"
import Helmet from 'react-helmet'
import { Link } from "gatsby"

const notFound = () => {
  return (
    <>
      <Helmet>
        <title>Page Not Found - Craft Boxing</title>
      </Helmet>
      <div style={{textAlign: "center", padding:"15vh 0", lineHeight: "1.5", height:'50vh'}}>
        <h3 className="versailles">Page Not Found</h3>
        <p>This page does not exist.<br />Head to our <Link to="/">homepage</Link></p>
      </div>
    </>
  )
}

export default notFound